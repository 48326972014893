import { Component } from '@angular/core';
import { WebAppVersionService } from './services/api-services/web-app-version.service';
import { LocalStorageService } from './services/api-services/local-storage.service';
import { AuthenticationService } from './services/api-services/authentication.service';
import { AuthData } from './models/auth-data.model';
import { isAfter, subMinutes } from 'date-fns';
import { ChatService } from './services/api-services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'd1a-dental-management';

  constructor(
    private webAppVersionService: WebAppVersionService,
    private storageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    private chatService: ChatService
  ) {
    this.chatService.refreshConnectionIfClosed();
    setInterval(() => {
      if (!this.storageService.getRefreshToken()) {
        return;
      }
      this.chatService.refreshConnectionIfClosed();
      const expirationDateTime = subMinutes(
        this.storageService.getAccessTokenExpirationTime(),
        29
      );
      if (isAfter(new Date(), expirationDateTime)) {
        this.authenticationService
          .refreshToken()
          .subscribe((authData: AuthData) => {
            this.storageService.setAccessToken(authData.accessToken);
            this.storageService.setAccessTokenExpirationTime(
              authData.accessTokenExpirationTime
            );
          });
      }
    }, 1000 * 60);
  }
}
