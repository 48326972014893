import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./log-in-page/log-in-page.module').then((m) => m.LogInPageModule),
  },
  {
    path: 'confirm-appointment/:confirmationHash/:source',
    loadChildren: () =>
      import('./confirm-appointment-page/confirm-appointment-page.module').then(
        (m) => m.ConfirmAppointmentPageModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'fdi-invitation/:invitationHash',
    loadChildren: () =>
      import('./fdi-invitation-page/fdi-invitation-page.module').then(
        (m) => m.FdiInvitationPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
