import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DateClearOffsetPipe } from './pipes/date-clear-offset.pipe';
import { UserInfoTypeToTextPipe } from './pipes/user-info-type-to-text.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared-components/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({ declarations: [AppComponent, DateClearOffsetPipe],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
        }),
        NgbModule,
        FontAwesomeModule,
        SocketIoModule.forRoot({ url: environment.realTimeApiUrl, options: {} }),
        ReactiveFormsModule,
        SharedModule,
        NgxEchartsModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        DateClearOffsetPipe,
        UserInfoTypeToTextPipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
